// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".chart-voiceover_srOnly-2zq-4{width:1px!important;height:1px!important;padding:0!important;margin:-1px!important;overflow:hidden!important;clip:rect(0,0,0,0)!important;white-space:nowrap!important;border:0!important}", "",{"version":3,"sources":["webpack://./react/js/styles/components/chart-voiceover.scss"],"names":[],"mappings":"AAGA,8BACE,mBAAA,CACA,oBAAA,CACA,mBAAA,CACA,qBAAA,CACA,yBAAA,CACA,4BAAA,CACA,4BAAA,CACA,kBAAA","sourcesContent":["@import '../variables';\n\n// Taken from Bootstrap's <https://github.com/twbs/bootstrap/blob/main/scss/mixins/_visually-hidden.scss>\n.srOnly {\n  width: 1px !important;\n  height: 1px !important;\n  padding: 0 !important;\n  margin: -1px !important;\n  overflow: hidden !important;\n  clip: rect(0, 0, 0, 0) !important;\n  white-space: nowrap !important;\n  border: 0 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"srOnly": "chart-voiceover_srOnly-2zq-4"
};
module.exports = ___CSS_LOADER_EXPORT___;
