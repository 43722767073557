import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import style from '../styles/footer.scss';

import NYCIcon from './icons/NYC';
import PrimaryLogo from "./icons/PrimaryLogo";
import PrimaryLogo_WorkforceDev from './icons/PrimaryLogoWorkforceDev';
import Logo from './Logo';
import LinkOut from './icons/LinkOut';

const Footer = ({ navData, thirdColumn, secondColumn, locale, ariaHidden }) => (
  <footer className={style.footer} aria-hidden={ariaHidden}>
    <div className={style.wrapper}>
      <div className={style.top}>
        <div className={style.line}>
          <Logo locale={locale} />
          {navData.map(({ url, label }, i) => (
              <NavLink to={`/${locale}/${url}`} key={i}>{label}</NavLink>
          ))}
        </div>
        <div className={style.divider}/>
        <div className={style.line}>
          <a className={style.logoLink} aria-label="NYC Mayor's Office for Economic Opportunity" href="https://www1.nyc.gov/site/opportunity/index.page" target="_blank">
            <PrimaryLogo width={550} height={100} />
          </a>
        </div>
        <div className={style.divider}/>
        <div className={style.bottom_line}>
          <div className={style.copyright}>
            <NYCIcon width={40} height={12} /> &copy; {(new Date().getFullYear())} City of New York. All Rights Reserved.<br />
            NYC is a trademark and service mark of the City of New York
          </div>
          <ul>
            {thirdColumn.map(({ url, label, isExternal }, i) => {
              if (isExternal === 'True'){
                return <li key={i} >
                <a href={url}>
                  {label}{' '}<LinkOut width={10} height={10} />
                </a>
              </li>
              }
              return (
              <li key={i} >
                <NavLink to={url}>
                  {label}
                </NavLink>
              </li>
            )})}
          </ul>
        </div>
      </div>
    </div>
  </footer>
);

Footer.propTypes = {
  navData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  thirdColumn: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  secondColumn: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

Footer.defaultProps = {
  secondColumn: [
    {
      url: '#',
      text: 'External Link',
      isExternal: "False"
    }, {
      url: '#',
      text: 'External Link',
      isExternal: "True"
    }
  ],
  thirdColumn: [
    {
      url: '#',
      text: 'Privacy Policy',
      isExternal: "True"
    }, {
      url: '#',
      text: 'Terms of Use',
      isExternal: "True"
    }
  ]
};

export default withStyles(style)(Footer);
