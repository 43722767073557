// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".data-stories_root-TSPpl{margin:0 6rem 11rem}@media screen and (max-width:499px){.data-stories_root-TSPpl{margin:0 3rem 4rem}}.data-stories_root-TSPpl .data-stories_content-z72B6{width:100%;max-width:128rem;margin:0 auto}", "",{"version":3,"sources":["webpack://./react/js/styles/data-stories.scss"],"names":[],"mappings":"AAEA,yBACE,mBAAA,CAEA,oCAHF,yBAII,kBAAA,CAAA,CAGF,qDACE,UAAA,CACA,gBAAA,CACA,aAAA","sourcesContent":["@import './variables';\n\n.root {\n  margin: 0 6rem 11rem;\n\n  @media screen and (max-width: $breakpoint-small) {\n    margin: 0 3rem 4rem;\n  }\n\n  .content {\n    width: 100%;\n    max-width: 128rem;\n    margin: 0 auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "data-stories_root-TSPpl",
	"content": "data-stories_content-z72B6"
};
module.exports = ___CSS_LOADER_EXPORT___;
