// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sidebar-sticky_root-1gm3H{display:block;position:relative;height:100%}.sidebar-sticky_sticky-1Iyk0{position:fixed;top:12rem;width:16rem}.sidebar-sticky_sticky-1Iyk0,.sidebar-sticky_stickyBottom-Fywbf{-webkit-animation:sidebar-sticky_all-1N55d ease-in-out .5;animation:sidebar-sticky_all-1N55d ease-in-out .5}.sidebar-sticky_stickyBottom-Fywbf{position:absolute;bottom:2.5rem;left:0;right:0}", "",{"version":3,"sources":["webpack://./react/js/styles/components/sidebar-sticky.scss"],"names":[],"mappings":"AAEA,2BACE,aAAA,CACA,iBAAA,CACA,WAAA,CAGF,6BACE,cAAA,CACA,SAAA,CACA,WACA,CAGF,gEAHE,yDAAA,CAAA,iDAQA,CALF,mCACE,iBAAA,CACA,aAAA,CACA,MAAA,CACA,OACA","sourcesContent":["@import '../variables';\n\n.root {\n  display: block;\n  position: relative;\n  height: 100%;\n}\n\n.sticky {\n  position: fixed;\n  top: 12rem;\n  width: 16rem;\n  animation: all 0.5 ease-in-out;\n}\n\n.stickyBottom {\n  position: absolute;\n  bottom: 2.5rem;\n  left: 0;\n  right: 0;\n  animation: all 0.5 ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "sidebar-sticky_root-1gm3H",
	"sticky": "sidebar-sticky_sticky-1Iyk0",
	"stickyBottom": "sidebar-sticky_stickyBottom-Fywbf",
	"all": "sidebar-sticky_all-1N55d"
};
module.exports = ___CSS_LOADER_EXPORT___;
