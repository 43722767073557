// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".index_root-qSn8J{display:flex;flex-direction:column;overflow-x:hidden;margin-bottom:2rem}@media screen and (max-width:499px){.index_root-qSn8J{margin-bottom:2rem}}.index_rtl-1yui4{direction:rtl}", "",{"version":3,"sources":["webpack://./react/js/styles/index.scss"],"names":[],"mappings":"AAEA,kBACE,YAAA,CACA,qBAAA,CACA,iBAAA,CACA,kBAAA,CAEA,oCANF,kBAOI,kBAAA,CAAA,CAIJ,iBACE,aAAA","sourcesContent":["@import 'variables';\n\n.root {\n  display: flex;\n  flex-direction: column;\n  overflow-x: hidden;\n  margin-bottom: 2rem;\n\n  @media screen and (max-width: $breakpoint-small) {\n    margin-bottom: 2rem;\n  }\n}\n\n.rtl {\n  direction: rtl;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "index_root-qSn8J",
	"rtl": "index_rtl-1yui4"
};
module.exports = ___CSS_LOADER_EXPORT___;
