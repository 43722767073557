// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._404_root-BTP_T{display:flex;justify-content:center;align-items:center;height:calc(100vh - 16rem)}", "",{"version":3,"sources":["webpack://./react/js/styles/components/404.scss"],"names":[],"mappings":"AAAA,iBACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,0BAAA","sourcesContent":[".root {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: calc(100vh - 16rem);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "_404_root-BTP_T"
};
module.exports = ___CSS_LOADER_EXPORT___;
