import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import style from '../styles/components/side-menu.scss';

class SideMenu extends Component {
  static propTypes = {
    navData: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ).isRequired,
    activeIndex: PropTypes.number,
  };

  constructor(props) {
    super(props);

    this.initialState = {
      navData: props.navData,
    };

    this.state = this.initialState;
  }

  setActiveLink = (url = '') => {
    const { navData } = this.state;
    const newNavData = navData.map((item, index) => {
      if((url === '' && index === 0) || item.url === url) {
        return { ...item, isActive: true };
      }

      return { ...item, isActive: false };
    });

    this.setState({ navData: newNavData });
  }

  handleNavigationClick = (url) => {
    this.setActiveLink(url);
  }

  componentDidMount = () => {
    const url = window.location.hash;
    if(url != null){
      const target = document.getElementById(url.substring(1));
      if(target){
        document.getElementById(target.scrollIntoView());
      }
    }
  }

  componentWillMount = () => {
    const url = window.location.hash;
    this.setActiveLink(url);
  }

  componentDidUpdate = (prevProps) => {
    const { activeIndex } = this.props;
    const { navData } = this.state;

    if(prevProps.activeIndex !== activeIndex) {
      const newNavData = navData.map((item, index) => {
        if(index === activeIndex) {
          return { ...item, isActive: true };
        }

        return { ...item, isActive: false };
      });

      this.setState({ navData: newNavData });
    }
  }

  render() {
    const { navData } = this.state;

    return (
      <nav id={'sidebar-menu'} className={style.root} aria-label="In page navigation">
        <ul className={style.ul}>
          {navData.map(({ url, label, isActive }, index) => {
            return (
              <li key={index} className={isActive ? style.active : ''} >
                <a href={url} onClick={() => this.handleNavigationClick(url)} >
                  {label}
                </a>
              </li>
            );
          })}
        </ul>
      </nav>
    );
  }
}

export default withStyles(style)(SideMenu);
