// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".caption-card_root-3vRfY{display:block;margin-top:6rem;margin-bottom:6rem}.caption-card_root-3vRfY.caption-card_divider-1sPat{border-bottom:1px solid #3194e0}.caption-card_root-3vRfY .caption-card_contentWrapper-1Bo4T{display:flex;justify-content:center;align-items:center}.caption-card_root-3vRfY .caption-card_contentWrapper-1Bo4T>img{width:100%;height:auto}.caption-card_root-3vRfY .caption-card_contentWrapper-1Bo4T>div{display:block;width:100%}.caption-card_root-3vRfY .caption-card_caption-Eyd8N{font-size:1.2rem;line-height:1.8rem;margin:2rem 0}", "",{"version":3,"sources":["webpack://./react/js/styles/components/caption-card.scss"],"names":[],"mappings":"AAEA,yBACE,aAAA,CACA,eAAA,CACA,kBAAA,CAEA,oDACE,+BAAA,CAGF,4DACE,YAAA,CACA,sBAAA,CACA,kBAAA,CAEA,gEACE,UAAA,CACA,WAAA,CAGF,gEACE,aAAA,CACA,UAAA,CAIJ,qDACE,gBAAA,CACA,kBAAA,CACA,aAAA","sourcesContent":["@import '../variables';\n\n.root {\n  display: block;\n  margin-top: 6rem;\n  margin-bottom: 6rem;\n  \n  &.divider {\n    border-bottom: 1px solid $link-blue;\n  }\n  \n  .contentWrapper {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    &> img {\n      width: 100%;\n      height: auto;\n    }\n\n    &> div {\n      display: block;\n      width: 100%;\n    }\n  }\n\n  .caption {\n    font-size: 1.2rem;\n    line-height: 1.8rem;\n    margin: 2rem 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "caption-card_root-3vRfY",
	"divider": "caption-card_divider-1sPat",
	"contentWrapper": "caption-card_contentWrapper-1Bo4T",
	"caption": "caption-card_caption-Eyd8N"
};
module.exports = ___CSS_LOADER_EXPORT___;
