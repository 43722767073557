import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import _ from 'lodash';
import classnames from 'classnames';

import style from '../styles/generic-page.scss';
import page from '../hocs/page';
import AgencyCard from '../components/AgencyCard';
// import UnorderedList from '../components/UnorderedList';
// import ALink from '../components/ALink';
import Loader from '../components/Loader';
import FourOhFour from '../components/404';

import { getGenericPage } from '../actions/genericPage';
import { contentWrapper, formatNavData } from '../utils/misc';
import SidebarSticky from '../components/SidebarSticky';
import SideMenu from '../components/SideMenu';
import Embed from '../components/Embed';
import CaptionCard from '../components/CaptionCard';

class GenericPage extends Component {
  static propTypes = {
    data: PropTypes.shape({}).isRequired,
    dispatch: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    match: PropTypes.shape({
      isExact: PropTypes.bool,
      params: PropTypes.shape({
        lang: PropTypes.string.isRequired,
      }),
      path: PropTypes.string,
      url: PropTypes.string,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.initialState = {
      foo: false,
      activeSection: 0,
    };

    this.state = this.initialState;
    this.sections = [];
  }

  handleSectionScroll = () => {
    const { data } = this.props;

    if(data.agency_items.length === 0) {
      const { activeSection } = this.state;

      if(this.sections.length === 0){
        const { contents } = this.props;
        const navData = formatNavData({ contents });

        this.sections = navData.map(({ label }) => (
          document.getElementById(encodeURIComponent(label))
        ));
      }

      for(let i = 0; i < this.sections.length; i++) {
        const section = this.sections[i];
        if(section){
          const currentSectionY = section.getBoundingClientRect().y + (section.getBoundingClientRect().height / 2);
          if(currentSectionY > 0) {
            if(activeSection !== i){
              this.setState({ ...this.state, activeSection: i });
            }
            break;
          }
        }
      }
    }
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    const { slug, lang } = match.params;
    dispatch(getGenericPage({ slug, lang }));
    window.addEventListener('scroll', this.handleSectionScroll, { passive: true });
  }

  componentDidUpdate(prevProps) {
    const { dispatch, match } = this.props;
    const { slug, lang } = match.params;
    if (!_.isEqual(match, prevProps.match)) {
      dispatch(getGenericPage({ slug, lang }));
      this.sections = [];
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleSectionScroll, { passive: true });
  }

  render() {
    const { isFetching, data, contents } = this.props;
    const { activeSection } = this.state;

    if (isFetching) {
      return <Loader />;
    }

    if (!Object.keys(data).length) {
      return <FourOhFour />;
    }

    const pageContents = contentWrapper({ contents });
    const navData = formatNavData({ contents });

    return (
      <div className={style.root}>
        {data.agency_items.length > 0 ? (
          <div className={style.contentWrapper}>
            <section className={style.content}>
              <div className={style.contentText}>
                <h1>{data.title}</h1>
              </div>
            </section>
            {pageContents.map((content, index) => {
              switch(content.type) {
                case 'wysiwyg':
                  return (
                    <section className={classnames(style.content, style.contentDesc)} key={index} id={encodeURIComponent(content.title)}>
                      <div className={style.contentText}>
                        {content.title && (<h2>{content.title}</h2>)}
                        {content.content && content.content}
                      </div>
                    </section>
                  );

                case 'image':
                  return (
                    <section key={index} id={encodeURIComponent(content.title)}>
                      <h2>{content.title}</h2>
                      <CaptionCard
                        content={<img src={content.src} alt={content.alt}></img>}
                        caption={content.caption}
                      />
                    </section>
                  );

                case 'embed':
                  return (
                    <section key={index} id={encodeURIComponent(content.title)}>
                      <h2>{content.title}</h2>
                      <Embed embedCode={content.embed_code} />
                    </section>
                  );
              }
            })}
            <section className={style.content}>
              <div className={style.agencyWrapper}>
                {data.agency_items.map(agency => (
                  <AgencyCard
                    key={agency.title}
                    image={
                      <img src={agency.image.url} alt={agency.image.alt} />
                    }
                    title={agency.title}
                    url={agency.link_url}
                    urlTitle={agency.link_title}
                    desc={agency.description}
                  />
                ))}
              </div>
            </section>
          </div>
        ) : (
          <div className={style.contentWrapper}>
            <section className={style.content}>
              <div className={style.left} />
              <div className={style.right}>
                <h1>{data.title}</h1>
              </div>
            </section>
            <section className={style.content}>
              <div className={style.left}>
                <SidebarSticky>
                  <SideMenu navData={navData} activeIndex={activeSection} />
                </SidebarSticky>
              </div>
              <div className={style.right}>
                {pageContents.map((content, index) => {
                  switch(content.type) {
                    case 'wysiwyg':
                      return (
                        <section key={index} id={encodeURIComponent(content.title)}>
                          {content.title && (<h2>{content.title}</h2>)}
                          {content.content && content.content}
                        </section>
                      );

                    case 'image':
                      return (
                        <section key={index} id={encodeURIComponent(content.title)}>
                          <CaptionCard
                            content={<img src={content.src} alt={content.alt}></img>}
                            caption={content.caption}
                          />
                        </section>
                      );

                    case 'embed':
                      return (
                        <section key={index} id={encodeURIComponent(content.title)}>
                          <h2>{content.title}</h2>
                          <Embed embedCode={content.embed_code} />
                        </section>
                      );
                  }
                })}
              </div>
            </section>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ genericPage }) => {
  const { isFetching, data, contents } = genericPage;
  return {
    data,
    isFetching,
    contents,
  };
};

export default compose(
  page({
    isDark: true,
  }),
  connect(mapStateToProps),
  withStyles(style),
)(GenericPage);
