// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loader_siteLoader-17TR6{position:fixed;top:0;left:0;width:100vw;height:100vh;background-color:#071f42;z-index:20000;display:flex;justify-content:center}", "",{"version":3,"sources":["webpack://./react/js/styles/components/loader.scss","webpack://./react/js/styles/variables.scss"],"names":[],"mappings":"AAEA,yBACE,cAAA,CACA,KAAA,CACA,MAAA,CACA,WAAA,CACA,YAAA,CACA,wBCHO,CDIP,aAAA,CAEA,YAAA,CACA,sBAAA","sourcesContent":["@import '../variables';\n\n.siteLoader {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100vw;\n  height: 100vh;\n  background-color: $alt-bg;\n  z-index: 20000;\n\n  display: flex;\n  justify-content: center;\n}\n","$white: #FFFFFF;\n$black: #000000;\n$off-black: #031837;\n\n$main-bg: #031837;\n$alt-bg: #071F42;\n$footer-bg: #061E41;\n$main-card-bg: #0C264D;\n$support-blue: #003C7A;\n$link-blue: #3194E0;\n$active-blue: #C9EEFE;\n$alt-link-blue: #1E78BE;\n$light-blue: #68B7F4;\n$green-1: #006333;\n$green-2: #008E46;\n$green-3: #4BAC4C;\n$green-4: #A8DD7C;\n$yellow-1: #FFD029;\n$yellow-2: #FAD457;\n$yellow-3: #F6D772;\n$yellow-4: #FDE9A6;\n$yellow-5: #FFDF8D;\n$red-1: #fd8e8e;\n\n$transition: 0.3s ease-out;\n\n$grid-cols: 12;\n$grid-col-width: 6rem;\n$grid-gutter-width: 4rem;\n\n// these are hardcoded here but not used dynamically until I can figure out how to use these in JSX\n// thus, just a list\n$z-index-header: 10000;\n$z-index-modal-bg: 10001;\n$z-index-jump-nav: 10002;\n$z-index-opportunity: 9999;\n\n$box-shadow: 0 0.2rem 1rem 0 rgba(0,0,0,0.20);\n\n$breakpoint-small: 499px;\n$breakpoint-medium: 999px;\n$breakpoint-large: 1160px;\n$breakpoint-xlarge: 1399px;\n\n$breakpoint-common-metrics-controls: 1383px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteLoader": "loader_siteLoader-17TR6"
};
module.exports = ___CSS_LOADER_EXPORT___;
