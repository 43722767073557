import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import _ from 'lodash';

import style from '../styles/components/navigation-menu.scss';
import NYCOpportunityIcon from './icons/NYCOpportunity';
import SelectField from './SelectField';

class NavigationMenu extends Component {
  static propTypes = {
    navData: PropTypes.arrayOf(
      PropTypes.shape({}),
    ).isRequired,
    isActive: PropTypes.bool.isRequired,
    isDark: PropTypes.bool,
    pathname: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    languageData: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        languageCode: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
    isMobileView: PropTypes.bool.isRequired,
    onMobileLanguageSelect: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isDark: false,
    onChange: null,
  };

  constructor(props) {
    super(props);

    this.initialState = {
      defaultMessage: null,
      currentMessage: null,
      messagePosition: 0,
    };

    this.state = this.initialState;
  }

  componentDidMount() {
    // this.setMessaging();
  }

  componentDidUpdate(prevProps) {
    // const { pathname } = this.props;
    // if (prevProps.pathname !== pathname) {
    //   this.setMessaging();
    // }
  }

  setMessaging = () => {
    const { navData, pathname } = this.props;
    const data = _.find(navData, { url: pathname.split('/')[2] });
    const defaultMessage = data ? data.desc : null;
    this.setState({
      ...this.state,
      defaultMessage,
      currentMessage: defaultMessage,
    });
  }

  handleMouseOverItem = ({ index }) => {
    const { navData } = this.props;
    const currentItem = document.getElementById(`navigation-menu-${index}`);
    const { top, height } = currentItem.getBoundingClientRect();
    const padding = 20;
    const currentItemPosition = top + (height/2) - padding;

    this.setState({
      ...this.state,
      messagePosition: currentItemPosition,
      currentMessage: navData[index].desc,
    });
  }

  handleMouseOutItem = () => {
    const { defaultMessage } = this.state;
    this.setState({
      ...this.state,
      currentMessage: defaultMessage,
    });
  }

  handleNavigationClick = () => {
    const { onChange } = this.props;
    if (onChange) {
      onChange();
    }
  }

  handleLanguageSelect = (event) => {
    const { value } = event.target;
    const { onMobileLanguageSelect } = this.props;
    onMobileLanguageSelect({
      languageCode: value,
    });
  }

  render() {
    const {
      isActive,
      isDark,
      navData,
      pathname,
      locale,
      languageData,
      isMobileView,
    } = this.props;
    const {
      currentMessage,
      messagePosition,
    } = this.state;
    const rootClasses = classnames(
      { [style.root]: true },
      { [style.active]: isActive },
    );

    const cornerStyles = classnames(
      style.corner,
      { [style.dark]: isDark },
    );

    const selectOptions = languageData.map(({ label, languageCode }) => ({
      label,
      value: languageCode,
    }));

    return (
      <div
        className={rootClasses}
        aria-expanded={isActive}
      >
        <div
          className={style.bg}
          role="presentation"
          aria-hidden="true"
          onClick={this.handleNavigationClick}
        />
        <div
          className={style.messaging}
          role="presentation"
          onClick={this.handleNavigationClick}
          aria-hidden="true"
        >
          {currentMessage && <p style={{ marginTop: messagePosition }} >{currentMessage}</p>}
        </div>
        <div className={style['nav-menu']}>
          <div className={cornerStyles} />
          {/* <div className={style.languageSelectWrapper}>
              <SelectField
                name="languge"
                value={locale}
                onChange={this.handleLanguageSelect}
                required
                options={selectOptions}
                isAlt
              />
            </div> */}
          {isMobileView && (
            <div className={style.languageSelectWrapper}>
              <SelectField
                name="languge"
                value={locale}
                onChange={this.handleLanguageSelect}
                required
                options={selectOptions}
                isAlt
              />
            </div>
          )}
          <ol className={style['menu-links']}>
            {navData.map(({ url, label }, index) => {
              const linkClasses = classnames(
                style['navigation-link'],
                { [style.active]: url === pathname.split('/')[2] },
              );

              return (
                <li
                  key={label}
                  id={`navigation-menu-${index}`}
                  className={linkClasses}
                  onMouseOver={() => this.handleMouseOverItem({ index })}
                  onMouseOut={this.handleMouseOutItem}
                  // todo:
                  onFocus={() => {}}
                  onBlur={() => {}}
                >
                  <NavLink
                    tabIndex={`${isActive ? 0 : -1}`}
                    to={`/${locale}/${url}`}
                    onClick={this.handleNavigationClick}
                  >
                    <span className={style.listCounter} aria-hidden>0{index + 1}</span>
                    {label}
                  </NavLink>
                </li>
              );
            })}
          </ol>
        </div>
      </div>
    );
  }
}

export default withStyles(style)(NavigationMenu);
