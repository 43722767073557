// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".data-stories-grid_dataStoriesGrid-2oJEQ{display:flex;flex-direction:column;margin:10rem 6rem 0}.data-stories-grid_dataStoriesGrid-2oJEQ .data-stories-grid_stories-Dw-1_{display:flex;flex-wrap:wrap;margin-top:10rem;justify-content:left}@media screen and (max-width:999px){.data-stories-grid_dataStoriesGrid-2oJEQ{margin:5rem 0 0}.data-stories-grid_dataStoriesGrid-2oJEQ .data-stories-grid_stories-Dw-1_{margin-top:4rem}}.data-stories-grid_dataStoriesGrid-2oJEQ .data-stories-grid_titleWrapper-w5nKV{width:100%;max-width:66rem;margin-left:0}", "",{"version":3,"sources":["webpack://./react/js/styles/containers/data-stories-grid.scss"],"names":[],"mappings":"AAEA,yCACE,YAAA,CACA,qBAAA,CACA,mBAAA,CAEA,0EACE,YAAA,CACA,cAAA,CACA,gBAAA,CACA,oBAAA,CAGF,oCAZF,yCAaI,eAAA,CAEA,0EACE,eAAA,CAAA,CAIJ,+EACE,UAAA,CACA,eAAA,CACA,aAAA","sourcesContent":["@import '../variables';\n\n.dataStoriesGrid {\n  display: flex;\n  flex-direction: column;\n  margin: 10rem 6rem 0;\n\n  .stories {\n    display: flex;\n    flex-wrap: wrap;\n    margin-top: 10rem;\n    justify-content: left;\n  }\n\n  @media screen and (max-width: $breakpoint-medium) {\n    margin: 5rem 0 0;\n\n    .stories {\n      margin-top: 4rem;\n    }\n  }\n\n  .titleWrapper {\n    width: 100%;\n    max-width: 66rem;\n    margin-left: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dataStoriesGrid": "data-stories-grid_dataStoriesGrid-2oJEQ",
	"stories": "data-stories-grid_stories-Dw-1_",
	"titleWrapper": "data-stories-grid_titleWrapper-w5nKV"
};
module.exports = ___CSS_LOADER_EXPORT___;
