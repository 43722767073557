export const industries = [
    'Non-profit',
    'Municipal government',
    'State government',
    'Federal government',
    'Philanthropic organization',
    'Academic institution',
    'Think tank',
    'Workforce intermediaries and collaboratives'
]

