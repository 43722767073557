// pages
export const ADD_PAGES = 'ADD_PAGES';
export const ADD_PAGE = 'ADD_PAGE';
export const PAGES_API_REQUEST = 'PAGES_API_REQUEST';
export const PAGES_API_SUCCESS = 'PAGES_API_SUCCESS';
export const PAGES_API_FAILURE = 'PAGES_API_FAILURE';

// data story
export const ADD_DATA_STORY = 'ADD_DATA_STORY';
export const DATA_STORY_API_REQUEST = 'DATA_STORY_API_REQUEST';
export const DATA_STORY_API_SUCCESS = 'DATA_STORY_API_SUCCESS';
export const DATA_STORY_API_FAILURE = 'DATA_STORY_API_FAILURE';

// data stories
export const ADD_DATA_STORIES = 'ADD_DATA_STORIES';
export const DATA_STORIES_API_REQUEST = 'DATA_STORIES_API_REQUEST';
export const DATA_STORIES_API_SUCCESS = 'DATA_STORIES_API_SUCCESS';
export const DATA_STORIES_API_FAILURE = 'DATA_STORIES_API_FAILURE';

// common metrics
export const ADD_COMMON_METRICS = 'ADD_COMMON_METRICS';
export const RESET_COMMON_METRICS = 'RESET_COMMON_METRICS';
export const COMMON_METRICS_API_REQUEST = 'COMMON_METRICS_API_REQUEST';
export const COMMON_METRICS_API_SUCCESS = 'COMMON_METRICS_API_SUCCESS';
export const COMMON_METRICS_API_FAILURE = 'COMMON_METRICS_API_FAILURE';
export const ADD_MARQUEE_NUMBER = 'ADD_MARQUEE_NUMBER';
export const MARQUEE_NUMBER_API_REQUEST = 'MARQUEE_NUMBER_REQUEST';
export const MARQUEE_NUMBER_API_SUCCESS = 'MARQUEE_NUMBER_SUCCESS';
export const MARQUEE_NUMBER_API_FAILURE = 'MARQUEE_NUMBER_FAILURE';
// Marquee number

// generic page
export const ADD_GENERIC_PAGE = 'ADD_GENERIC_PAGE';
export const GENERIC_PAGE_API_REQUEST = 'GENERIC_PAGE_API_REQUEST';
export const GENERIC_PAGE_API_SUCCESS = 'GENERIC_PAGE_API_SUCCESS';
export const GENERIC_PAGE_API_FAILURE = 'GENERIC_PAGE_API_FAILURE';

// modal
export const SET_MODAL = 'SET_MODAL';
export const CLEAR_MODAL = 'CLEAR_MODAL';

// programs
export const ADD_PROGRAMS = 'ADD_PROGRAMS';
export const PROGRAMS_API_REQUEST = 'PROGRAMS_API_REQUEST';
export const PROGRAMS_API_SUCCESS = 'PROGRAMS_API_SUCCESS';
export const PROGRAMS_API_FAILURE = 'PROGRAMS_API_FAILURE';


// charts
export const CHART_EXPORT_API_REQUEST = 'CHART_EXPORT_API_REQUEST'
export const CHART_EXPORT_API_SUCCESS = 'CHART_EXPORT_API_SUCCESS'
export const CHART_EXPORT_API_FAILURE = 'CHART_EXPORT_API_FAILURE'
export const MAP_ADDED = 'MAP_ADDED';
export const SANKEY_ADDED = 'SANKEY_ADDED';

// subscribe
export const SUBSCRIBE_REQUEST = 'SUBSCRIBE_REQUEST';
export const SUBSCRIBE_SUCCESS = 'SUBSCRIBE_SUCCESS';
export const SUBSCRIBE_FAILED = 'SUBSCRIBE_FAILED';
export const SUBSCRIBE_EMAIL = 'SUBSCRIBE_EMAIL';

