import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon';

const PrimaryLogo_WorkforceDev = (props) => {
  const {
    fill,
  } = props;

  return (
    <Icon viewBox="0 0 482 78" {...props}>
  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g fill="currentColor" fillRule="nonzero">
      <path d="M245.52 26.94C251.26 26.94 254.5 31.15 254.5 36.43C254.5 41.71 251.26 45.92 245.52 45.92C239.78 45.92 236.54 41.71 236.54 36.43C236.54 31.15 239.79 26.94 245.52 26.94ZM245.52 41.83C248.58 41.83 249.75 39.13 249.75 36.42C249.75 33.71 248.58 31.01 245.52 31.01C242.46 31.01 241.29 33.71 241.29 36.42C241.29 39.13 242.46 41.83 245.52 41.83Z" fill="white"/>
      <path d="M257.69 35.1H255.6V32.3H257.69C257.69 28.5 259.02 27.33 262.71 27.33C263.4 27.33 264.11 27.38 264.8 27.41V30.47C264.39 30.42 264.01 30.39 263.63 30.39C262.64 30.39 262.02 30.52 262.02 31.72V32.31H264.62V35.11H262.02V45.54H257.69V35.1Z" fill="white"/>
      <path d="M266.97 35.1H264.88V32.3H266.97C266.97 28.5 268.3 27.33 271.99 27.33C272.68 27.33 273.39 27.38 274.08 27.41V30.47C273.67 30.42 273.29 30.39 272.91 30.39C271.92 30.39 271.3 30.52 271.3 31.72V32.31H273.9V35.11H271.3V45.54H266.97V35.1ZM279.49 30.63H275.16V27.32H279.49V30.63ZM275.15 32.29H279.48V45.52H275.15V32.29Z" fill="white"/>
      <path d="M291.02 37.14C290.99 36.02 290 35.23 288.9 35.23C286.48 35.23 286.1 37.3 286.1 39.13C286.1 40.84 286.84 42.6 288.68 42.6C290.18 42.6 290.95 41.73 291.15 40.33H295.36C294.98 43.87 292.22 45.91 288.7 45.91C284.72 45.91 281.76 43.18 281.76 39.13C281.76 34.92 284.44 31.91 288.7 31.91C291.99 31.91 294.92 33.64 295.23 37.14H291.02Z" fill="white"/>
      <path d="M301.06 39.97C301.14 41.78 302.18 42.98 304.02 42.98C305.07 42.98 306.08 42.5 306.49 41.53H310.54C309.75 44.59 306.92 45.92 303.96 45.92C299.65 45.92 296.72 43.32 296.72 38.88C296.72 34.83 299.96 31.92 303.86 31.92C308.6 31.92 310.97 35.49 310.77 39.98H301.06V39.97ZM306.44 37.54C306.36 36.06 305.32 34.84 303.86 34.84C302.33 34.84 301.31 35.94 301.06 37.54H306.44Z" fill="white"/>
      <path d="M327 31.91C331.1 31.91 333.99 34.94 333.99 38.92C333.99 42.9 331.11 45.91 327 45.91C322.89 45.91 320.04 42.9 320.04 38.92C320.04 34.94 322.89 31.91 327 31.91ZM327 42.59C329.01 42.59 329.65 40.6 329.65 38.92C329.65 37.24 329.01 35.22 327 35.22C324.99 35.22 324.37 37.23 324.37 38.92C324.37 40.6 324.98 42.59 327 42.59Z" fill="white"/>
      <path d="M337.04 35.1H334.95V32.3H337.04C337.04 28.5 338.37 27.33 342.06 27.33C342.75 27.33 343.46 27.38 344.15 27.41V30.47C343.74 30.42 343.36 30.39 342.98 30.39C341.99 30.39 341.37 30.52 341.37 31.72V32.31H343.97V35.11H341.37V45.54H337.04V35.1Z" fill="white"/>
      <path d="M371.34 45.52H366.6L364.1 32.98H364.05L361.55 45.52H356.73L351.81 27.31H356.55L359.13 40.62H359.18L361.86 27.31H366.32L369 40.62H369.05L371.63 27.31H376.37L371.34 45.52Z" fill="white"/>
      <path d="M382.71 31.91C386.81 31.91 389.7 34.94 389.7 38.92C389.7 42.9 386.82 45.91 382.71 45.91C378.6 45.91 375.75 42.9 375.75 38.92C375.75 34.94 378.61 31.91 382.71 31.91ZM382.71 42.59C384.72 42.59 385.36 40.6 385.36 38.92C385.36 37.24 384.72 35.22 382.71 35.22C380.7 35.22 380.08 37.23 380.08 38.92C380.09 40.6 380.7 42.59 382.71 42.59Z" fill="white"/>
      <path d="M391.71 32.29H395.92V34.61H395.97C396.61 32.93 398.09 32.03 399.95 32.03C400.28 32.03 400.64 32.06 400.97 32.13V36.08C400.41 35.93 399.9 35.82 399.31 35.82C397.17 35.82 396.05 37.3 396.05 38.96V45.51H391.72V32.29H391.71Z" fill="white"/>
      <path d="M402.27 27.32H406.6V36.7L410.55 32.29H415.57L410.73 37.29L416.19 45.53H410.96L407.72 40.2L406.6 41.3V45.53H402.27V27.32Z" fill="white"/>
      <path d="M418.44 35.1H416.35V32.3H418.44C418.44 28.5 419.77 27.33 423.46 27.33C424.15 27.33 424.86 27.38 425.55 27.41V30.47C425.14 30.42 424.76 30.39 424.38 30.39C423.39 30.39 422.77 30.52 422.77 31.72V32.31H425.37V35.11H422.77V45.54H418.44V35.1Z" fill="white"/>
      <path d="M433.1 31.91C437.2 31.91 440.09 34.94 440.09 38.92C440.09 42.9 437.21 45.91 433.1 45.91C428.99 45.91 426.14 42.9 426.14 38.92C426.14 34.94 428.99 31.91 433.1 31.91ZM433.1 42.59C435.11 42.59 435.75 40.6 435.75 38.92C435.75 37.24 435.11 35.22 433.1 35.22C431.09 35.22 430.47 37.23 430.47 38.92C430.47 40.6 431.08 42.59 433.1 42.59Z" fill="white"/>
      <path d="M442.36 32.29H446.57V34.61H446.62C447.26 32.93 448.74 32.03 450.6 32.03C450.93 32.03 451.29 32.06 451.62 32.13V36.08C451.06 35.93 450.55 35.82 449.96 35.82C447.82 35.82 446.7 37.3 446.7 38.96V45.51H442.37V32.29H442.36Z" fill="white"/>
      <path d="M461.28 37.14C461.25 36.02 460.26 35.23 459.16 35.23C456.74 35.23 456.36 37.3 456.36 39.13C456.36 40.84 457.1 42.6 458.94 42.6C460.44 42.6 461.21 41.73 461.41 40.33H465.62C465.24 43.87 462.48 45.91 458.96 45.91C454.98 45.91 452.02 43.18 452.02 39.13C452.02 34.92 454.7 31.91 458.96 31.91C462.25 31.91 465.18 33.64 465.49 37.14H461.28Z" fill="white"/>
      <path d="M471.32 39.97C471.4 41.78 472.44 42.98 474.28 42.98C475.33 42.98 476.34 42.5 476.75 41.53H480.8C480.01 44.59 477.18 45.92 474.22 45.92C469.91 45.92 466.98 43.32 466.98 38.88C466.98 34.83 470.22 31.92 474.12 31.92C478.86 31.92 481.23 35.49 481.03 39.98H471.32V39.97ZM476.7 37.54C476.62 36.06 475.58 34.84 474.12 34.84C472.59 34.84 471.57 35.94 471.32 37.54H476.7Z" fill="white"/>
      <path d="M237.24 54.67H245.07C250.65 54.67 253.99 58.06 253.99 63.7C253.99 69.67 250.6 72.88 245.07 72.88H237.24V54.67ZM241.98 68.8H244.22C247.82 68.72 249.24 67.32 249.24 63.68C249.24 60.36 247.46 58.76 244.22 58.76H241.98V68.8Z" fill="white"/>
      <path d="M259.96 67.32C260.04 69.13 261.08 70.33 262.92 70.33C263.97 70.33 264.98 69.85 265.39 68.88H269.44C268.65 71.94 265.82 73.27 262.86 73.27C258.55 73.27 255.62 70.67 255.62 66.23C255.62 62.18 258.86 59.27 262.76 59.27C267.5 59.27 269.87 62.84 269.67 67.33H259.96V67.32ZM265.34 64.89C265.26 63.41 264.22 62.19 262.76 62.19C261.23 62.19 260.21 63.29 259.96 64.89H265.34Z" fill="white"/>
      <path d="M279.57 72.87H274.57L270.16 59.64H274.67L277.09 68.39H277.14L279.59 59.64H284L279.57 72.87Z" fill="white"/>
      <path d="M288.92 67.32C289 69.13 290.04 70.33 291.88 70.33C292.93 70.33 293.94 69.85 294.35 68.88H298.4C297.61 71.94 294.78 73.27 291.82 73.27C287.51 73.27 284.58 70.67 284.58 66.23C284.58 62.18 287.82 59.27 291.72 59.27C296.46 59.27 298.83 62.84 298.63 67.33H288.92V67.32ZM294.3 64.89C294.22 63.41 293.18 62.19 291.72 62.19C290.19 62.19 289.17 63.29 288.92 64.89H294.3Z" fill="white"/>
      <path d="M300.81 54.67H305.14V72.88H300.81V54.67Z" fill="white"/>
      <path d="M314.37 59.26C318.47 59.26 321.36 62.29 321.36 66.27C321.36 70.25 318.48 73.26 314.37 73.26C310.26 73.26 307.41 70.25 307.41 66.27C307.41 62.29 310.27 59.26 314.37 59.26ZM314.37 69.94C316.38 69.94 317.02 67.95 317.02 66.27C317.02 64.59 316.38 62.57 314.37 62.57C312.36 62.57 311.74 64.58 311.74 66.27C311.75 67.95 312.36 69.94 314.37 69.94Z" fill="white"/>
      <path d="M323.48 59.64H327.61V61.17H327.66C328.5 59.92 329.78 59.26 331.31 59.26C335.47 59.26 337.23 62.83 337.23 66.55C337.23 69.99 335.39 73.26 331.65 73.26C330.02 73.26 328.77 72.65 327.88 71.45H327.83V77.31H323.5V59.64H323.48ZM327.66 66.12C327.66 68.44 328.32 69.94 330.29 69.94C332.23 69.94 332.89 67.93 332.89 66.12C332.89 64.44 332.23 62.58 330.31 62.58C329.14 62.57 327.66 63.39 327.66 66.12Z" fill="white"/>
      <path d="M339.39 59.64H343.6V61.45H343.65C344.44 60.12 345.84 59.26 347.45 59.26C349.11 59.26 350.59 59.8 351.3 61.4C352.35 60 353.65 59.26 355.43 59.26C359.64 59.26 360.1 62.45 360.1 64.97V72.87H355.77V65.1C355.77 63.67 355.08 62.83 353.96 62.83C352.1 62.83 351.92 64.26 351.92 66.4V72.88H347.59V65.36C347.59 63.8 347.13 62.84 345.96 62.84C344.4 62.84 343.74 63.73 343.74 66.43V72.88H339.41V59.64H339.39Z" fill="white"/>
      <path d="M366.55 67.32C366.63 69.13 367.67 70.33 369.51 70.33C370.56 70.33 371.57 69.85 371.98 68.88H376.03C375.24 71.94 372.41 73.27 369.45 73.27C365.14 73.27 362.21 70.67 362.21 66.23C362.21 62.18 365.45 59.27 369.35 59.27C374.09 59.27 376.46 62.84 376.26 67.33H366.55V67.32ZM371.92 64.89C371.84 63.41 370.8 62.19 369.34 62.19C367.81 62.19 366.79 63.29 366.54 64.89H371.92Z" fill="white"/>
      <path d="M378.28 59.64H382.49V61.45H382.54C383.33 60.1 385.12 59.26 386.7 59.26C391.11 59.26 391.47 62.47 391.47 64.38V72.87H387.14V66.47C387.14 64.66 387.34 62.82 384.97 62.82C383.34 62.82 382.62 64.2 382.62 65.65V72.87H378.29V59.64H378.28Z" fill="white"/>
      <path d="M402.17 62.45H399.49V68.47C399.49 69.52 400.1 69.75 401.05 69.75C401.41 69.75 401.79 69.7 402.17 69.7V72.89C401.38 72.92 400.59 73.02 399.8 73.02C396.1 73.02 395.16 71.95 395.16 68.35V62.46H392.97V59.66H395.16V55.66H399.49V59.66H402.17V62.45Z" fill="white"/>
      <path d="M214.44 31.88L219 27.37V13.69L205.31 0H159.69L146 13.69V4.56L141.44 0H123.19L118.63 4.56V13.69L109.5 22.81L100.38 13.69V4.56L95.81 0H77.56L73 4.56L68.44 0H50.19L45.63 4.56V22.81L22.81 0H4.56L0 4.56V68.44L4.56 73H22.81L27.38 68.44V50.19L50.19 73H68.44L73 68.44V31.94L95.81 54.75V68.44L100.38 73H118.63L123.19 68.44V54.75L146 31.94V59.31L159.69 73H205.31L219 59.31V45.63L214.44 41.06H191.62V45.63H173.38V27.37H191.62V31.88H214.44Z" fill="white"/>
      <path d="M215.75 3.08C216.18 3.08 216.55 3.04 216.55 2.54C216.55 2.12 216.14 2.07 215.8 2.07H215.11V3.08H215.75ZM215.12 5.04H214.55V1.59H215.86C216.69 1.59 217.12 1.88 217.12 2.58C217.12 3.21 216.74 3.46 216.22 3.52L217.19 5.04H216.55L215.66 3.56H215.13V5.04H215.12ZM215.73 0.680005C214.38 0.680005 213.33 1.74001 213.33 3.26C213.33 4.68001 214.25 5.84 215.73 5.84C217.07 5.84 218.12 4.79 218.12 3.26C218.12 1.74001 217.07 0.680005 215.73 0.680005ZM215.73 6.37C213.94 6.37 212.66 5.03 212.66 3.25C212.66 1.37 214.08 0.130005 215.73 0.130005C217.36 0.130005 218.78 1.37 218.78 3.25C218.79 5.13 217.37 6.37 215.73 6.37Z" fill="white"/>
    </g>
  </g>
    </Icon>
  );
};

PrimaryLogo_WorkforceDev.propTypes = {
  fill: PropTypes.string,
};

PrimaryLogo_WorkforceDev.defaultProps = {
  fill: '#ffffff',
};

export default PrimaryLogo_WorkforceDev;
