// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".info-tooltip_infoTooltip-3t8g5{position:relative}.info-tooltip_tipBox-3mSkE{background-color:#071f42;padding:1.7rem 2rem;border:2px solid #122f5a;pointer-events:none;font-family:Public Sans,sans-serif;font-size:16px;max-width:200px;line-height:2.4rem}", "",{"version":3,"sources":["webpack://./react/js/styles/components/opportunity_standard/info-tooltip.scss","webpack://./react/js/styles/opportunity-standard-variables.scss"],"names":[],"mappings":"AAEA,gCACE,iBAAA,CAGF,2BACE,wBCyBW,CDxBX,mBAAA,CACA,wBAAA,CACA,mBAAA,CACA,kCAAA,CACA,cAAA,CACA,eAAA,CACA,kBAAA","sourcesContent":["@import '../../opportunity-standard-variables';\n\n.infoTooltip {\n  position: relative;\n}\n\n.tipBox {\n  background-color: $wdp-alt-bg;\n  padding: 1.7rem 2rem;\n  border: 2px solid $scale-default-4;\n  pointer-events: none;\n  font-family: 'Public Sans', sans-serif;\n  font-size: calc($controls-font-size - 1px);\n  max-width: 200px;\n  line-height: 2.4rem;\n}","$scale-default-0: #03142C;\n$scale-default-1: #031837;\n$scale-default-2: #071F42;\n$scale-default-3: #0C264D;\n$scale-default-4: #122F5A;\n$scale-default-5: #003C7A;\n\n$scale-light-0: white;\n$scale-light-1: #FAFDFF;\n$scale-light-2: #F0F6FA;\n$scale-light-3: #E3F1FD;\n$scale-light-4: #C9E7FC;\n$scale-light-5: #B8E0FC;\n\n$green-light-2: #A8DD7C;\n$green-light-3: #DFEED2;\n\n$red-light-2: #FC9CAC;\n\n$blue-light-1: #568ADF;\n$blue-light-2: #53B3FC;\n\n$scale-light-5: #B8E0FC;\n\n$white: #FEFEFE;\n\n$unmatched-1: #081836; // Background of common metric selection banner\n$unmatched-2: #11244A; // Background of common metric cards\n$unmatched-3: #0c1834; // Gap between common metric cards\n\n$controls-font-size: 17px;\n\n$wdp-alt-bg: #071F42;\n$wdp-main-bg: #031837;\n\n$yellow: #FFBE17;\n\n$breakpoint-tablet: 999px;\n$breakpoint-mobile: 674px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoTooltip": "info-tooltip_infoTooltip-3t8g5",
	"tipBox": "info-tooltip_tipBox-3mSkE"
};
module.exports = ___CSS_LOADER_EXPORT___;
